import api from "@/assets/js/api";
// const { VUE_APP_UCENTER } = process.env
import {
  VUE_APP_UCENTER,
  VUE_APP_APP,
  VUE_APP_ID,
  VUE_APP_KEY,
  VUE_APP_ENV,
} from "@/assets/js/stationSetting";

// // 忘记密码四合一提交验证码
// export function submitForgetUniversal(data = {}) {
//   return api({
//     url: `${VUE_APP_UCENTER}/UCenter/Account/submitForgetUniversal`,
//     data,
//   });
// }
// 忘记密码四合一提交验证码
export function submitForgetUniversal(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/V3/UCenter/Forget/verifyUniversal`,
    data,
  });
}

// 检测手机号
export function forgetMobile(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Account/forgetMobile`,
    data,
  });
}

// 检测邮箱
export function forgetEmail(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Account/forgetEmail`,
    data,
  });
}

// 检测账号
export function forgetAccount(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Account/forgetAccount`,
    data,
  });
}

// 通过手机号进行信息验证
export function submitForgetMobile(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Account/submitForgetMobile`,
    data,
  });
}

// 通过邮箱进行信息验证

export function submitForgetEmail(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Account/submitForgetEmail`,
    data,
  });
}

//通过谷歌进行信息验证
export function submitForgetGoogle(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Account/submitForgetGoogle`,
    data,
  });
}

// // 密码重置
// export function forgetCheckUpdate(data = {}) {
//   return api({
//     url: `${VUE_APP_UCENTER}/UCenter/Account/forgetCheckUpdate`,
//     data,
//   });
// }
// 密码重置
export function forgetCheckUpdate(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/V3/UCenter/Forget/resetPwd`,
    data,
  });
}
