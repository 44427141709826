<template>
  <div class="forget-pwd-container-bv">
    <!-- 登录 -->
    <video muted="true" loop="" autoplay="autoplay" class="bgvideo">
      <source type="video/mp4" src="../../assets/img/bv/night-banner.mp4" />
    </video>
    <div class="forget-pwd">
      <!-- title  -->

      <div v-if="isInCheck">
        <h1 class="title">{{ translateTitle("安全验证") }}</h1>
      </div>
      <div v-else-if="isInChange">
        <h1 class="title">{{ translateTitle("设置新密码") }}</h1>
        <h2 class="sub-title">
          {{ translateTitle("重置登录密码后，24小时内禁止提币和OTC划转") }}
        </h2>
      </div>
      <div v-else>
        <h1 class="title">{{ translateTitle("重置登录密码") }}</h1>
        <h2 class="sub-title">
          {{ translateTitle("重置登录密码后，24小时内禁止提币和OTC划转") }}
        </h2>
      </div>

      <!-- 表单 -->
      <div class="forget-pwd-form">
        <el-form
          v-if="!isInCheck && !isInChange"
          ref="checkForm"
          :rules="formRules"
          :model="forgetData"
          label-position="top"
          @submit.native.prevent
        >
          <!-- 邮箱 -->
          <el-form-item prop="account" class="account">
            <el-input
              @input="onFilterAccount"
              v-model.trim="forgetData.account"
              :placeholder="translateTitle('邮箱 / 手机 / 用户名')"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <!-- 登录 -->
          <el-button
            @click="onShowVcode"
            class="forget-pwd-btn theme-btn "
            type="primary"
            :loading="forgetPwdLoading"
            >{{ translateTitle("提交") }}</el-button
          >
          <div class="back-login">
            <router-link
              class="cancel"
              :to="routeSplicing(language, 'login')"
              >{{ translateTitle("取消") }}</router-link
            >
          </div>
        </el-form>
        <check-account
          v-if="isInCheck"
          @onCancel="onCancel"
          :validInfo="validInfo"
          @secret="onSecret"
        ></check-account>
        <ConfirmPwd
          v-if="isInChange"
          :token="pwdToken"
          :onlyName="only_name"
        ></ConfirmPwd>
      </div>
    </div>
    <Vcode
      @close="onCloseVcode"
      :imgs="imgs"
      :show="isShow"
      @success="success"
      :successText="translateTitle('验证通过')"
      :failText="translateTitle('验证失败，请重试')"
      :sliderText="translateTitle('拖动滑块完成拼图')"
    />
    <Geetest :isGeet="isShowGeet" :isRegister="false" @geetParam="success" />
    <!-- 版权 -->
    <!-- <div class="copyright">
      <span>Copyright &copy; 2021, Topcredit All Rights Reserved</span>
    </div> -->
  </div>
</template>

<script>
import { VUE_APP_ID } from "@/assets/js/stationSetting";
import { mapGetters } from "vuex";
import {
  forgotPwdCheckAccount,
  forgotPwdCheckAccountGeet,
} from "@/model/user/account";
import { showMessage } from "@/components/dialog/message";
// import InputNumber from "@/components/common/InputNumber";
import CheckAccount from "./components/CheckAccount";
import ConfirmPwd from "./components/ConfirmPwd";
import { translateTitle } from "@/assets/js/i18n";
import Vcode from "vue-puzzle-vcode"; //页面内引用插件
import img1 from "../../assets/img/rePhoto/1.png";
import img2 from "../../assets/img/rePhoto/2.png";
import routeSplicing from "@/assets/js/routeSplicing";
import Geetest from "@/components/Geetest.vue";
export default {
  data() {
    return {
      img1,
      img2,
      imgs: [],
      isShow: false,
      forgetData: {},
      forgetPwdLoading: false,
      only_name: "",
      isInCheck: false,
      isInChange: false,
      validInfo: {},
      formRules: {
        account: [
          {
            required: true,
            message: this.translateTitle(`邮箱/手机号/用户名不能为空`),
            trigger: "change",
          },
        ],
      },
      isShowGeet: false,
      geetParam: null,
      pwdToken: "",
    };
  },
  components: {
    ConfirmPwd,
    CheckAccount,
    Vcode,
    Geetest,
    // InputNumber
  },
  computed: {
    ...mapGetters({
      language: "user/language",
    }),
  },
  created() {
    if (
      VUE_APP_ID == "AwyOTFRlsfQ5mRkqwCNaEd5T" ||
      VUE_APP_ID == "DwyOTFRlsfQ5mRkqwCNaEd5T" ||
      VUE_APP_ID == "J8tstegyx2UjYzJ6J1vNuD3w"
    ) {
      this.imgs.push(img1);
    } else {
      this.imgs.push(img2);
    }
  },
  methods: {
    routeSplicing,
    translateTitle,
    onFilterAccount(val) {
      this.$nextTick(() => {
        this.$refs.checkForm.validateField("account");
      });
    },
    onSecret(val) {
      this.pwdToken = val;
      // this.only_name = val;
      this.isInChange = true;
      this.isInCheck = false;
    },
    success(geetParam) {
      this.geetParam = geetParam;
      this.onSubmit();
      this.isShow = false; // 通过验证后，手动隐藏模态框
    },
    onCloseVcode() {
      this.isShow = false;
    },
    onCancel() {
      this.isInCheck = false;
    },
    onShowVcode() {
      this.$refs.checkForm.validate(valid => {
        if (valid) {
          // this.isShow = true;
          this.isShowGeet = !this.isShowGeet;
        } else {
          this.isShow = false;
        }
      });
    },
    onSubmit() {
      this.$refs.checkForm.validate(valid => {
        if (valid) {
          const data = {
            login_name: this.forgetData.account,
            ...this.geetParam,
          };
          this.forgetPwdLoading = true;
          forgotPwdCheckAccountGeet(data).then(rs => {
            this.forgetPwdLoading = false;
            if (rs.status == 200) {
              const {
                validate_email,
                validate_google,
                validate_mobile,
              } = rs.data;
              if (
                validate_email == 0 &&
                validate_google == 0 &&
                validate_mobile == 0
              ) {
                showMessage({ message: "账号未绑定谷歌或邮箱，无法重置密码" });
                return;
              } else {
                this.isInCheck = true;
                this.validInfo = rs.data;
                sessionStorage.setItem(
                  "user_info",
                  JSON.stringify(this.validInfo)
                );
              }
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.forget-pwd-container-bv {
  width: 100%;
  min-width: 1260px;
  height: calc(100vh - 60px);
  text-align: left;
  background-image: linear-gradient(174deg, #19273c 12%, #273244 92%);
  background-size: 100% 100%;
  min-height: 600px;
  box-sizing: border-box;
  // padding-top: -70px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  .el-input__inner {
    height: 48px !important;
  }*/
  .bgvideo {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  /* 登录 */
  .forget-pwd {
    width: 500px;
    min-height: 245px;
    // margin-top: 80px auto 0;
    // background: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, 0.16);
    border-radius: 4px;
    position: relative;
    z-index: 5;
    padding-bottom: 33px;
    box-sizing: border-box;
    position: absolute;
    .back-login {
      display: flex;
      justify-content: center;

      margin-top: 20px;
      .cancel {
        cursor: pointer;
        // color: #3a7dff;
        color: #f7b239;
        font-size: 14px;
      }
    }

    .title {
      // color: #354052;
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      padding: 23px 31px 29px;
      padding-bottom: 0;
      text-align: center;
    }
    .sub-title {
      margin-top: 15px;
      text-align: center;
      font-size: 14px;
      // color: #354052;
      color: #fff;
      font-weight: 400;
    }

    /* 表单 */
    .forget-pwd-form {
      padding: 0 50px;
      margin-top: 30px;
      .pwd {
        margin-bottom: 15px;
      }
      .el-form {
        input {
          height: 48px;
          border-radius: 2px;
          &:focus {
            border-color: #c61b3d;
          }
        }
        .el-input__inner {
          background-color: transparent;
          border-top-width: 0px;
          border-left-width: 0px;
          border-right-width: 0px;
          border-bottom: 1px solid #d8d8d8;
          color: #d8d8d8;
          padding-left: 0px;
        }
        /* 每一项 */
        .el-form-item {
          padding-top: 3px;
        }

        /* 邮箱 */
        .account {
          margin-top: 0px;
        }

        /* 登录 */
        .forget-pwd-btn {
          width: 100%;
          height: 48px;
          color: #fff;
          font-size: 16px;
          line-height: 48px;
          text-align: center;
          border-radius: 2px;
          background-image: linear-gradient(267deg, #ff486d 10%, #c61b3d 41%);
          padding: 0;
          border: 0;
          cursor: pointer;
          &:hover {
            background-image: linear-gradient(267deg, #ff486d 10%, #c61b3d 41%);
          }
        }
      }
    }
  }

  /* 版权 */
  .copyright {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 64px;
    font-size: 14px;
    text-align: center;
    color: #fff;
  }
  .validator-form {
    .sercurity-title {
      .sercurity {
        color: #f7b239;
      }
    }
    .cancel-btns {
      .cancel {
        color: #f7b239;
      }
    }
  }
}
</style>
