<template>
  <div class="login_container">
    <ForgetPwdInner v-if="defaultPage"></ForgetPwdInner>
    <ForgetPwdInnerBv v-if="is8V"></ForgetPwdInnerBv>
  </div>
</template>
<script>
import { VUE_UNION_STATION } from "@/assets/js/stationSetting";
import { translateTitle } from "@/assets/js/i18n";
import { mapActions, mapGetters } from "vuex";
import ForgetPwdInner from "@/pages/login/forgetPwd-inner.vue";
import ForgetPwdInnerBv from "@/pages/login/forgetPwd-inner-bv.vue";
export default {
  mixins: [],

  data() {
    return {
      is8V: false,
      defaultPage: false
    };
  },
  components: { ForgetPwdInner, ForgetPwdInnerBv },
  computed: {},
  created() {
    if (VUE_UNION_STATION == "8V") {
      this.is8V = true;
    } else {
      this.defaultPage = true;
    }
  },
  beforeDestroy() {},
  destroyed() {},
  mounted() {},
  methods: {
    translateTitle
  }
};
</script>
<style lang="scss"></style>
